import React, { lazy, Suspense } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import FullLoading from './components/FullLoading'
import AppLayout from './layouts/AppLayout'
import Home from './pages/home/'
import NotFound from './pages/404/404'

// const Foundation = lazy(() => import(/* webpackChunkName:'Foundation' */ './pages/foundation/index'))
const Ecosystem = lazy(() => import(/* webpackChunkName:'Ecosystem' */ './pages/ecosystem/index'))
const Lock = lazy(() => import(/* webpackChunkName:'Lock' */ './pages/lock/index'))
const KCS = lazy(() => import(/* webpackChunkName:'KCS' */ './pages/kcs/index'))

import './App.less'
import { useMobileMenuShow } from './state/application/hooks'
import { useResponsive } from './utils/responsive'
import styled from 'styled-components'

export default function App() {
  const show = useMobileMenuShow()
  const { isMobile } = useResponsive()

  // initSensor()

  // React.useEffect(() => {
  //   registerPage()
  // }, [])

  React.useEffect(() => {
    const bodyDom = document.getElementsByTagName('body')[0]
    if (show && isMobile) {
      bodyDom.style.overflowY = 'hidden'
      bodyDom.style.overflowX = 'hidden'
    } else {
      bodyDom.style.overflowY = 'auto'
      bodyDom.style.overflowX = 'auto'
    }
  }, [show, isMobile])

  return (
    <Suspense fallback={<FullLoading />}>
      <AppLayout>
        <Switch>
          <Route path="/" exact={true} component={Home} />
          <Route path="/kcs-ecosystem" exact={true} component={Ecosystem} />
          <Route path="/token-distribution" exact={true} component={Lock} />
          <Route path="/kcs" exact={true} component={KCS} />
          <Route path="/404" component={NotFound} />
          <Route path="*">
            <Redirect to="/404" />
          </Route>
        </Switch>
      </AppLayout>
    </Suspense>
  )
}
