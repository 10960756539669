import React from 'react'
import styled from 'styled-components'
import Row from '../Row'
import { ParagraphText } from '../Common/index'
import { useTranslation } from 'react-i18next'
import Text from '../Text/index'
import { FadeInUp } from '../../utils/animation'

export interface PictureProps {
  url: string
  width?: string
  title: string
  description: any
  direction: string
}

const PictureAWrap = styled.div`
  & + & {
    margin-top: 150px;
  }
  @media (max-width: 768px) {
    & + & {
      margin-top: 60px;
    }
  }
`

const PictureWrap = styled(Row)<{ direction: string }>`
  align-items: center;
  justify-content: ${({ direction }) => (direction === 'left' ? 'space-between' : 'space-between')};
  @media (max-width: 768px) {
    margin-bottom: 60px;
    flex-flow: column nowrap;
  }
`
const ImageDescription = styled(ParagraphText)<{ direction: string }>`
  order: ${({ direction }) => (direction === 'left' ? 1 : 2)};
  width: auto;
  max-width: 480px;
  text-align: left;
  font-size: 20px;
  padding-left: ${({ direction }) => (direction === 'left' ? '0px' : '80px')};
  @media (max-width: 768px) {
    font-size: 16px;
    padding-left: ${({ direction }) => (direction === 'left' ? '0px' : '16px')};
    padding-right: ${({ direction }) => (direction === 'right' ? '0px' : '16px')};
  }
`

const Image = styled.img<{ width: string; direction: string }>`
  width: ${({ width }) => width};
  order: ${({ direction }) => (direction === 'left' ? 2 : 1)};
  transition: 0.3s all ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
  @media (max-width: 768px) {
    margin-top: 64px;
    width: 70%;
    height: auto;
  }
`

const TextWrap = styled.div<{ direction: string }>`
  order: ${({ direction }) => (direction === 'left' ? 1 : 2)};
`

const Title = styled.h2`
  font-family: 'Barlow Bold';
  font-style: normal;
  font-weight: bold;
  font-size: 54px;
  line-height: 54px;
  /* identical to box height, or 148% */
  color: #ffffff;
  @media (max-width: 768px) {
    font-size: 32px;
    line-height: 80px;
    /* identical to box height, or 250% */
  }
`

const Description = styled(Text)`
  font-family: 'Barlow Light';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 32px;
  /* or 200% */
  color: #ffffff;
  max-width: 534px;
  @media (max-width: 768px) {
    font-size: 14px;
    margin-top: 24px;
  }
`

const Line = styled.div`
  width: 35px;
  height: 7px;
  background: #00ce87;
  margin: 24px 0;
  @media (max-width: 768px) {
    margin: 0;
  }
`

const SloganPicture: React.FunctionComponent<PictureProps> = (props) => {
  const { t } = useTranslation()
  return (
    <PictureAWrap>
      <PictureWrap direction={props.direction}>
        <Image src={props.url} width={props.width ?? '240px'} height="240px" direction={props.direction}></Image>
        <TextWrap direction={props.direction}>
          <Title>{props.title}</Title>
          <Line />
          <Description>{props.description}</Description>
        </TextWrap>
      </PictureWrap>
    </PictureAWrap>
  )
}

export default SloganPicture
