import { Carousel } from 'antd'
import React from 'react'
import styled from 'styled-components'
import { Image } from '../../../components'
import Text from '../../../components/Text/index'

interface Props {}

const FocusWrap = styled.div`
  width: 100%;
  background: #0c1013;
  padding-bottom: 0px;
`

const FocusContent = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 0px;
  padding-bottom: 0px;
  @media (max-width: 768px) {
    padding-bottom: 32px;
  }
`

const Title = styled.div`
  font-family: 'Barlow Bold';
  font-style: normal;
  font-weight: bold;
  font-size: 36px;
  line-height: 158px;
  /* identical to box height, or 111% */
  color: #ffffff;
  @media (max-width: 768px) {
    font-size: 28px;
    line-height: 40px;
    text-align: center;
    padding: 0 20px;
  }
`

const CarouselWrap = styled.div`
  width: 100%;
  background: #000;
  position: relative;
  @media (max-width: 768px) {
    margin-bottom: 0px;
  }
`

const BackgroundImg = require('../../../assets/images/foundation/f1.png').default
const BackgroundImg1 = require('../../../assets/images/home/focus-bg.png').default

const StyledCarousel = styled.div`
  width: 100%;
  max-width: 1250px;
  position: relative;
  margin: 0 auto;
  background: url(${BackgroundImg}) 0px 0px no-repeat;
  background-size: auto 120%;
  @media (max-width: 768px) {
    background: url(${BackgroundImg1}) right 400px no-repeat;
  }
`

const CarouselPanel = styled.div`
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  flex-flow: row nowrap;
  justify-content: flex-end;
  align-items: center;
  height: 690px;
  @media (max-width: 768px) {
    height: auto;
    left: 0px;
    top: 0px;
    padding: 24px;
    padding-bottom: 50px;
    justify-content: flex-start;
    align-items: flex-start;
  }
`

const TextWrap = styled.div`
  display: flex;
  flex-flow: column nowrap;
  margin-right: 100px;
  max-width: 460px;
  position: relatIve;
  z-index: 1;
  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0px;
  }
`
const CTitle = styled.h2`
  width: auto;
  font-family: 'Barlow Bold';
  font-style: normal;
  font-weight: bold;
  font-size: 54px;
  line-height: 36px;
  /* identical to box height, or 100% */
  color: #ffffff;
  padding-right: 40px;
  @media (max-width: 768px) {
    width: 100%;
    font-size: 32px;
    padding-right: 0px;
    word-break: keep-all;
    white-space: nowrap;
    margin-bottom:0px ;
  }
`

const Line = styled.div<{ width?: string }>`
  width: ${({ width }) => {
    if (width) {
      return width
    }
    return '269px'
  }};
  height: 1px;
  background: #5b5b5b;
  margin-top: 24px;
  @media (max-width: 768px) {
    width: 269px;
  }
`

const CDescription = styled.div`
  font-family: Barlow;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 30px;
  /* or 200% */
  color: #ffffff;
  margin-top: 24px;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const RowLineWrap = styled.div`
  position: absolute;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  align-items: center;
  right: calc((100vw - 1200px) / 2 + 10px);
  top: 40px;
  height: 516px;
  width: 1px;
  @media (max-width: 768px) {
    right: 36px;
  }
`

const RowLine = styled.div`
  width: 1px;
  height: 180px;
  background: #5b5b5b;
  @media (max-width: 768px) {
    position: relative;
    z-index: 9999;
  }
`

const DotGroup = styled.div`
  display: flex;
  width: auto;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  z-index: 9999;
`

const Dot = styled.div<{ active: boolean }>`
  margin: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  cursor: pointer;
  background: ${({ active }) => {
    if (active) {
      return '#c4c4c4'
    }
    return 'transparent'
  }};
  border: ${({ active }) => {
    if (active) {
      return 'none'
    }
    return '1px solid #c4c4c4'
  }};
`

const CarouselList = [
  {
    title: 'Outlook',
    description:
      'With further development of the KuCoin exchange ecosystem and the gradual improvement of the KCC ecosystem, we have built an ecosystem based on blockchain technology around KCS with self-circulating value. Not only has it provided a payment system that can serve hundreds of millions of users around the world, but it has also accelerated the arrival of the Web 3.0 era. Moreover, the spectrum of diversified application scenarios based on the KCS ecosystem also improves the ease of use of blockchain technology and its related applications, lowers the threshold for ordinary users to enter the crypto world, and truly pushes blockchain technology from the geeks to the masses. In the process of promoting the fusion of the crypto world and the traditional world, it will also open the door to the future of the digital world for us.',
    icon: require('../../../assets/images/foundation/f1.png').default,
  },
]

export const Focus = (props: Props) => {
  const ref = React.useRef<any>()

  const [nth, setNth] = React.useState(1)

  const onChange = (current: number) => {
    setNth(() => current)
  }
  const goTo = (index: number) => {
    ref.current.goTo(index)
  }

  return (
    <FocusWrap>
      <CarouselWrap>
        {/* <RowLineWrap>
          <RowLine />
          <RowLine />
        </RowLineWrap> */}
        <StyledCarousel>
          {CarouselList.map((item, index) => {
            return (
              <div key={index}>
                <CarouselPanel>
                  {/* <CarouselPanelBgWrap>
                    <Image src={item.icon} height="100%" width="auto" />
                  </CarouselPanelBgWrap> */}
                  <TextWrap>
                    <CTitle>{item.title}</CTitle>
                    <Line width={index === 2 ? '350px' : undefined} />
                    <CDescription>{item.description}</CDescription>
                  </TextWrap>
                </CarouselPanel>
              </div>
            )
          })}
        </StyledCarousel>
      </CarouselWrap>
    </FocusWrap>
  )
}
