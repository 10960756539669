import React from 'react'
import styled from 'styled-components'
import AppMenu from '../AppMenu'
import { MobileView, BrowserView } from '../Common'
import { MenuOutlined, CloseOutlined } from '@ant-design/icons'
import { useMobileMenuShow } from '../../state/application/hooks'
import { useDispatch } from 'react-redux'
import { changeMobileMenuShow } from '../../state/application/actions'
import { useResponsive } from '../../utils/responsive'
import { withRouter, useHistory } from 'react-router-dom'
import { KCSLogo } from '../../constants'
import { Link } from 'react-router-dom'

const AppHeaderWrap = styled.div<{ menuOpen: boolean }>`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  height: 80px;
  width: 100%;
  max-width: 1200px;
  padding: 0;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background: transparent;
  z-index: 99;
  @media (max-width: 768px) {
    padding: 0 24px;
    background: ${({ menuOpen }) => {
      if (menuOpen) {
        return '#000708'
      }
      return 'transparent'
    }};
    position: absolute;
    z-index: 9999;
    left: 0;
    width: 100%;
    transform: translateX(0);
  }
`

const Box = styled.div`
  display: flex;
  align-items: center;
`

const HeaderLeftWrap = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  @media (min-width: 1200px) {
    padding-left: 0px;
  }
`

const AppHeaderContent = styled(HeaderLeftWrap)<{ isMobile: boolean }>`
  justify-content: flex-start;
  width: 100%;
`

const ButtonGroup = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
`

const KCSLogoWrap = styled.img`
  width: 40px;
  height: 40px;
  cursor: pointer;
  @media (max-width: 768px) {
    margin-left: 28px;
  }
`

const AppHeader: React.FunctionComponent = (props: any) => {
  // const [mobileMenuShow, setMobileMenuShow] = React.useState(false)
  const show = useMobileMenuShow()
  const { isMobile } = useResponsive()

  const history = useHistory()

  const dispatch = useDispatch()

  const walletButtonShow = React.useMemo(() => {
    return props.location.pathname.startsWith('/bridge')
  }, [props.location.pathname])

  return (
    <AppHeaderWrap menuOpen={show}>
      <AppHeaderContent isMobile={isMobile}>
        <Box>
          <MobileView style={{ width: '24px' }}>
            {!show ? (
              <MenuOutlined
                style={{ fontSize: '18px', color: '#fff' }}
                onClick={() => {
                  dispatch(changeMobileMenuShow({ show: true }))
                }}
              />
            ) : (
              <CloseOutlined
                style={{ fontSize: '20px', color: '#fff' }}
                onClick={() => {
                  dispatch(changeMobileMenuShow({ show: false }))
                }}
              />
            )}

            {show ? <AppMenu style={{ width: '100%' }} /> : null}
          </MobileView>
        </Box>
        <HeaderLeftWrap>
          <Link to="/">
            <KCSLogoWrap
              src={KCSLogo}
              onClick={() => {
                dispatch(changeMobileMenuShow({ show: false }))
              }}
            />
          </Link>
          <BrowserView>
            <AppMenu style={{ width: '800px', position: 'relative', top: '3px' }} />
          </BrowserView>
        </HeaderLeftWrap>
      </AppHeaderContent>
    </AppHeaderWrap>
  )
}

export default withRouter(AppHeader)
