import { Carousel } from 'antd'
import React, { CSSProperties } from 'react'
import styled from 'styled-components'
import { Box } from '../../../components/Box'
import Text from '../../../components/Text/index'
import { theme } from '../../../constants/theme'
import { Image } from '../../../components/index'
import { FunctionComponent } from 'react'
import CountUp from '../../../components/CuntUp'
import { useResponsive } from '../../../utils/responsive'

interface Props {}

const SliderWrap = styled.div`
  width: 100%;
  @media (max-width: 768px) {
    padding: 0 20px;
  }
`
const SliderContent = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
`

const ButtonGroup = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 120px;
  height: 54px;
  background: #0c1013;
  user-select: none;
  margin-top: -20px;
  @media (max-width: 768px) {
    margin-top: -20px;
  }
`

const Button = styled.div`
  background: transparent;
  color: #fff;
  border-radius: 0px;
  width: 60px;
  height: 54px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  border: 1px solid #5b5b5b;
  cursor: pointer;
  & + & {
    border-left: none;
  }
`
const ControlBar = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  right: 0px;
  top: 16px;
  z-index: 99;
  padding: 20px;
  @media (max-width: 768px) {
    left: 0;
    padding: 0px;
    top: 0px;
  }
`
const ArrorIcon = styled.img`
  width: 24px;
  height: 24px;
  transition: all 0.3s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
`

const StyledCarousel = styled(Carousel)`
  padding-bottom: 96px;
  padding-top: 0px;
  position: relative;
  @media (max-width: 768px) {
    padding-top: 0px;
    padding-bottom: 0px;
    height: auto;
  }
`

const CarouselOne = styled.div`
  display: flex;
  width: 100%;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 57px;
  @media (max-width: 768px) {
    flex-flow: column nowrap;
    margin-top: 0px;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 0px;
  }
`
const P = styled.div`
  font-family: 'Barlow Light';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 40px;
  color: #ffffff;
  width: 100%;
  max-width: 630px;
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 32px;
  }
`

const P1 = styled.div`
  font-family: 'Barlow Light';
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 40px;
  color: #ffffff;
  max-width: 630px;
  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 26px;
  }
`

const DotGroup = styled.div`
  display: flex;
  width: auto;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  z-index: 1;
  bottom: 80px;
  left: 0;
  user-select: none;
  @media (max-width: 768px) {
    left: 100%;
    transform: translateX(-100%);
    top: 140px;
    height: 54px;
    width: 50px;
    z-index: 9999;
  }
`

const Dot = styled.div<{ active: boolean }>`
  & + & {
    margin-left: 8px;
  }
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: ${({ active }) => {
    if (active) {
      return '#c4c4c4'
    }
    return 'transparent'
  }};
  border: ${({ active }) => {
    if (active) {
      return 'none'
    }
    return '1px solid #c4c4c4'
  }};
`

const CrossLineWrap = styled.div`
  height: 5px;
  height: 5px;
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
`

const CrossIccon = styled.img`
  width: 5px;
  height: 5px;
`
const CrossBorder = styled.div`
  background: #5b5b5b;
  height: 1px;
  flex: 1;
`

const Line = styled.div`
  background: #ffffff;
  width: 553px;
  opacity: 0.5;
  height: 1px;
  @media (max-width: 768px) {
    display: none;
  }
`

export const CrossLine: FunctionComponent<{ styles?: CSSProperties }> = ({ styles }) => {
  return (
    <CrossLineWrap style={styles}>
      <CrossIccon src={require('../../../assets/images/home/cross.png').default} />
      <CrossBorder />
    </CrossLineWrap>
  )
}

const TitleWithNumberWrap = styled.div`
  width: 350px;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const SliderTitle = styled.h2`
  font-family: 'Barlow Bold';
  font-style: normal;
  font-weight: 700;
  line-height: 64px;
  font-size: 64px;
  /* or 246% */
  display: flex;
  color: #ffffff;
  transition: all 0.1s ease-out;
  opacity: 1;
  @media (max-width: 768px) {
    font-size: 36px;
    line-height: 120px;
  }
`

const TitleWithNumber: FunctionComponent<{ styles?: CSSProperties; title: string; amount: number }> = ({
  title,
  amount,
  styles,
}) => {
  const { isMobile } = useResponsive()
  return (
    <TitleWithNumberWrap style={styles}>
      <Text
        fontSize={isMobile ? '20px' : '22px'}
        fontWeight={300}
        color="#fff"
        style={{ width: isMobile ? '100%' : '355px' }}
      >
        {title}
      </Text>
      <CrossLine styles={{ marginTop: '20px' }} />
      <Text
        fontSize={isMobile ? '48px' : '60px'}
        fontWeight={500}
        fontFamily="Barlow Bold"
        color={theme.colors.primary}
        lineHeight="80px"
        style={{ marginTop: '10px' }}
      >
        {/* {amount} */}
        <CountUp end={amount} />
      </Text>
      <CrossLine styles={{ marginTop: '15px' }} />
    </TitleWithNumberWrap>
  )
}

export const Slider = ({ sliderRef }: { sliderRef: any }) => {
  const [nth, setNth] = React.useState(0)

  const [sliding0, setSliding0] = React.useState(false)
  const [sliding1, setSliding1] = React.useState(false)
  const [sliding2, setSliding2] = React.useState(false)

  const onChange = (current: number) => {
    setNth(() => current)
  }

  const goTo = (index: number) => {
    ref.current.goTo(index)
  }

  const hidePreTitle = (nth: number) => {
    if (nth === 0) {
      setSliding0(() => true)
      setTimeout(() => {
        setSliding0(() => false)
      }, 1000)
    }
    if (nth === 1) {
      setSliding1(() => true)
      setTimeout(() => {
        setSliding1(() => false)
      }, 1000)
    }
    if (nth === 2) {
      setSliding2(() => true)
      setTimeout(() => {
        setSliding2(() => false)
      }, 1000)
    }
  }

  const hideNextTitle = (nth: number) => {
    if (nth === 0) {
      setSliding1(() => true)
      setTimeout(() => {
        setSliding1(() => false)
      }, 200)
    }
    if (nth === 1) {
      setSliding2(() => true)
      setTimeout(() => {
        setSliding2(() => false)
      }, 200)
    }
    if (nth === 2) {
      setSliding0(() => true)
      setTimeout(() => {
        setSliding0(() => false)
      }, 200)
    }
  }

  const goPrev = () => {
    hidePreTitle(nth)
    ref.current.prev()
  }

  const goNext = () => {
    hideNextTitle(nth)
    ref.current.next()
  }

  const ref = React.useRef<any>()

  const { isMobile } = useResponsive()

  return (
    <SliderWrap ref={sliderRef}>
      <SliderContent>
        <ControlBar>
          <SliderTitle>Motivation</SliderTitle>
          <ButtonGroup>
            <Button onClick={goPrev}>
              <ArrorIcon src={require('../../../assets/images/home/left-arrow.png').default} />
            </Button>
            <Button onClick={goNext}>
              <ArrorIcon src={require('../../../assets/images/home/right-arrow.png').default} />
            </Button>
          </ButtonGroup>
        </ControlBar>

        {isMobile && (
          <DotGroup>
            <Dot active={nth === 0} />
            <Dot active={nth === 1} />
            <Dot active={nth === 2} />
          </DotGroup>
        )}
        <div style={{ position: 'relative' }}>
          {!isMobile && (
            <DotGroup>
              <Dot active={nth === 0} />
              <Dot active={nth === 1} />
              <Dot active={nth === 2} />
            </DotGroup>
          )}

          <StyledCarousel initialSlide={0} ref={ref} dots={false} autoplay={true} afterChange={onChange}>
            <div>
              <CarouselOne style={{ height: isMobile ? '340px' : 'auto' }}>
                <Box>
                  <Line />
                  <P style={{ marginTop: isMobile ? '62px' : '42px' }}>
                    Since the birth of Bitcoin in 2009, the crypto world has experienced a revolution that saw
                    blockchain 1.0 to blockchain 2.0, and blockchain N.0. Cryptocurrency has expanded beyond the
                    characteristics of a currency to include the capabilities of an application with the smart contract
                    as its basis, with an endless supply of new application types. Decentralized finance, decentralized
                    computing, decentralized storage, Web3.0 etc.
                  </P>
                  <P1 style={{ marginTop: isMobile ? '24px' : '0px' }}>
                    Each new bold attempt at innovation brings not only new technological improvement in this area, but
                    also changes to the world. Decentralized finance advances financial inclusion, decentralized
                    computing and decentralized storage allows every byte of information to be executed and recorded
                    while Web3.0 redefines the relationship between productivity and production.
                  </P1>
                </Box>
                <Box style={{ marginLeft: isMobile ? '0%' : '108px', marginTop: isMobile ? '44px' : '0px' }}>
                  <Image
                    style={{ width: isMobile ? '100%' : '420px', height: 'auto' }}
                    src={require('../../../assets/images/home/spotbg.png').default}
                  />
                </Box>
              </CarouselOne>
            </div>
            <div>
              <CarouselOne>
                <Box>
                  <Line />
                  <P style={{ marginTop: isMobile ? '62px' : '27px' }}>
                    We believe that in the future, blockchain technology and its related applications will be applied on
                    a large scale and profoundly change our lives, akin to the Internet today.
                  </P>
                  <P1 style={{ marginTop: isMobile ? '24px' : '20px' }}>
                    The ecosystem and the applications derived from blockchain technology will continue to benefit
                    mankind. However, while blockchain technology continues to move towards mass application, there are
                    still urgent problems that need to be solved. For example, more than 90% of the world's population
                    has never been exposed to cryptocurrencies or the associated technology. The rapid development of
                    the crypto world (such as the emergence of new technologies, new concepts and new applications) will
                    also increase the threshold for new users to enter the crypto world (such as NFT transactions, Layer
                    2s and cross-chain transactions, etc.). Moreover, there remains a huge "disconnection" between the
                    crypto world and the traditional world.
                  </P1>
                </Box>
                <Box style={{ marginLeft: isMobile ? '0%' : '108px', marginTop: isMobile ? '44px' : '0px' }}>
                  <Image
                    style={{ width: isMobile ? '100%' : '420px', height: 'auto' }}
                    src={require('../../../assets/images/home/spotbg2.png').default}
                  />
                </Box>
              </CarouselOne>
            </div>
            <div>
              <CarouselOne style={{ height: isMobile ? '340px' : 'auto' }}>
                <Box>
                  <Line />
                  <P1 style={{ marginTop: '70px' }}>
                    Since its establishment in 2017, KCS ecosystem has been an observer, a participant, and a
                    beneficiary within the crypto industry. For the next phase of the industry’s development, KCS team
                    would like to further capitalize on the key advantages, such as our influence, platform, technology,
                    talents etc., to build a self-circulating ecosystem based on KCS, thereby lowering the barriers for
                    ordinary users to enter the world of the blockchain, thus allowing blockchain technology and its
                    related applications to truly move from the geeks to the masses while allowing more individuals and
                    organizations to make better use of blockchain technology and advance it.
                  </P1>
                </Box>
                <Box style={{ marginLeft: isMobile ? '0%' : '108px', marginTop: isMobile ? '44px' : '0px' }}>
                  <Image
                    style={{ width: isMobile ? '100%' : '420px', height: isMobile ? 'auto' : 'auto' }}
                    src={require('../../../assets/images/home/spotbg1.png').default}
                  />
                </Box>
              </CarouselOne>
            </div>
          </StyledCarousel>
        </div>
      </SliderContent>
    </SliderWrap>
  )
}
