import React from 'react'
import styled from 'styled-components'
import Text from '../../../components/Text/index'
import { open } from '../../../utils'
import { FadeInUp } from '../../../utils/animation'
import { BuyKCSWithMojitoSwap, BuyKCSWithKuCoin } from '../../../constants/index'
import { useResponsive } from '../../../utils/responsive'

interface Props {}

const CardWrap = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;
  @media (max-width: 768px) {
    padding: 0 20px;
  }
`

const CardContent = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 120px;
  padding-bottom: 120px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    flex-flow: column nowrap;
    padding-top: 120px;
    padding-bottom: 80px;
  }
`

const StyledCard = styled.div<{ bg: string }>`
  width: 584px;
  height: 240px;
  border-radius: 12px;
  padding: 40px;
  background: url(${({ bg }) => bg}) bottom right no-repeat, #293238;
  background-size: 34% 100%;
  transition: all 0.3s ease-in-out;
  &:hover {
    background-size: 50% 120%;
  }
  @media (max-width: 768px) {
    width: 100%;
    height: 400px;
    background-size: 50% 50%;
    & + & {
      margin-top: 20px;
    }
    &:hover {
      background-size: 60% 60%;
    }
  }
`

const Button = styled.a`
  height: 54px;
  margin-top: 26px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 180px;
  font-family: Barlow;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  /* identical to box height, or 160% */
  /* 亮色主题文字/标题 l 正文 */
  color: #00142a;
  background: linear-gradient(90deg, #ffffff -2.45%, #ffffff 101.4%);
  cursor: pointer;
  font-weight: bold;
  &:hover {
    color: #00142a;
  }
  @media (max-width: 768px) {
    width: 100%;
  }
`

const Title = styled(Text)`
  font-family: Barlow;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 40px;
  max-width: 382px;
  /* or 143% */
  color: #ffffff;
  @media (max-width: 768px) {
    max-width: 280px;
  }
`

const Row = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 768px) {
    flex-flow: column nowrap;
  }
`

const LogoIcon = styled.img`
  width: 30px;
  height: 30px;
`

export const Card = (props: Props) => {
  const { isMobile } = useResponsive()
  return (
    <CardWrap>
      <FadeInUp>
        <CardContent>
          <StyledCard bg={require('../../../assets/images/home/green-bg.png').default}>
            <Title>Get the Cryptocurrency That Powers the KCC Ecosystem</Title>
            <Row>
              <Button
                href={BuyKCSWithMojitoSwap}
                target="_blank"
                // onClick={() => {
                //   window.$KcSensors.trackClick(['KcsHomeDex', '1'])
                // }}
              >
                <Row style={{ flexFlow: 'row nowrap' }}>
                  <LogoIcon src={require('../../../assets/images/home/mojito.svg').default} />
                  <span style={{ marginLeft: '8px' }}>Buy with Dex</span>
                </Row>
              </Button>
              <Button
                href={BuyKCSWithKuCoin}
                target="_blank"
                style={{ marginLeft: isMobile ? '0px' : '14px' }}
                // onClick={() => {
                //   window.$KcSensors.trackClick(['KcsHomeCex', '1'])
                // }}
              >
                <Row style={{ flexFlow: 'row nowrap' }}>
                  <LogoIcon src={require('../../../assets/images/home/kucoin.svg').default} />
                  <span style={{ marginLeft: '8px' }}> Buy with Cex</span>
                </Row>
              </Button>
            </Row>
          </StyledCard>

          <StyledCard bg={require('../../../assets/images/home/gray-bg.png').default}>
            <Title style={{ marginTop: isMobile ? '52px' : '20px' }}>Get Our White Paper</Title>
            <Button
              href={`${window.location.protocol}//${window.location.host}/kcs-whitepaper.pdf`}
              target="_blank"
              style={{ width: isMobile ? '100%' : '185px', marginTop: isMobile ? '88px' : '46px' }}
              // onClick={() => {
              //   window.$KcSensors.trackClick(['KcsHomeDownload', '1'])
              // }}
            >
              Download Now
            </Button>
          </StyledCard>
        </CardContent>
      </FadeInUp>
    </CardWrap>
  )
}
