import React from 'react'
import { isMobile } from 'react-device-detect'
import styled from 'styled-components'

export const TitleText = styled.span`
  font-family: Barlow;
  font-size: 48px;
  color: #fff;
  text-align: center;
  @media (max-width: 768px) {
    width: 100%;
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    text-align: left;
  }
`

interface Props {}

const BenifitList = [
  {
    icon: require('../../../assets/images/home/icon1.png').default,
    desc: 'Pledge KCS to obtain rights to new projects and other benefits in the ecosystem',
  },
  {
    icon: require('../../../assets/images/home/icon2.png').default,
    desc: 'Part of on-chain transaction fees (gas fee)',
  },
  {
    icon: require('../../../assets/images/home/icon3.png').default,
    desc: 'Loan products',
  },
  {
    icon: require('../../../assets/images/home/icon4.png').default,
    desc: 'Game, NFT service and transaction fees',
  },
  {
    icon: require('../../../assets/images/home/icon5.png').default,
    desc: 'Resource consumption fees such as storage and bandwidth',
  },
  {
    icon: require('../../../assets/images/home/icon6.png').default,
    desc: 'Investment income of ecological projects',
  },
  {
    icon: require('../../../assets/images/home/icon7.png').default,
    desc: 'Liquidity gains',
  },
]

const BenefitWrap = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  position: relative;
  z-index: 1;
  align-items: center;
  @media (max-width: 768px) {
    padding: 0 20px;
  }
`

const Img = styled.img`
  width: 86px;
  height: 86px;
`
const Line = styled.div`
  width: 360px;
  background: #fff;
  height: 1px;
  margin: 32px 0;
  opacity: 0.7;
  @media (max-width: 768px) {
    width: 100%;
    margin: 32px 0 16px 0;
  }
`
const Text = styled.div`
  font-family: 'URWDIN-Regular';
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  /* or 175% */
  color: #ffffff;
  max-width: 360px;
`

const ListWrap = styled.div`
  max-width: 880px;
  margin-top: 120px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: 768px) {
    margin-top: 60px;
  }
`

const ListItem = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 80px;
  &:nth-child(even) {
    margin-left: 160px;
  }
  @media (max-width: 768px) {
    &:nth-child(even) {
      margin-left: 0px;
    }
    margin-bottom: 50px;
    padding: 0 20px;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
  }
`

export default function Benefits() {
  return (
    <BenefitWrap>
      <ListWrap>
        {BenifitList.map((item, index) => {
          return (
            <ListItem key={index}>
              <Img src={item.icon} />
              <Line />
              <Text>{item.desc}</Text>
            </ListItem>
          )
        })}
      </ListWrap>
    </BenefitWrap>
  )
}
