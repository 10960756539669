import React, { FunctionComponent, useEffect } from 'react'
import styled from 'styled-components'
import Row, { RowBetween } from '../../components/Row'
import { media } from '../../constants'
import { useResponsive } from '../../utils/responsive'
import Text from '../Text'
import { Image } from '../../components'
import { open } from '../../utils'

export const Container = styled.div<{ width?: string }>`
  width: ${({ width }) => width ?? '1200px'};
  margin-top: 80px;
  margin: 0 auto;
  padding:0 24px;
  @media (max-width: 768px) {
    margin-top: 0px;
  }
`

const FooterWrap = styled.div<{ transparent?: boolean }>`
  width: 100%;
  background: #1e1e1e;

  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;

  height: 80px;
  margin-top: 0px;
  @media (max-width: 768px) {
    margin-top: 0px;
    height: 140px;
  }
`
const MediaItem = styled.a`
  height: 42px;
  width: 42px;
  border-radius: 20px;
  background: #ffffff33;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 24px;
  :hover {
    background: #b8c6d8;
    // opacity: .75;
  }
`

interface FooterProps {
  transparent?: boolean
}

const Footer: React.FunctionComponent<FooterProps> = (props) => {
  const { isMobile } = useResponsive()

  const renderMedia = (data: any, index: any) => {
    return (
      <MediaItem href={data?.route} target="_blank" key={index} className={data?.app}>
        <img src={data?.icon} style={{ width: '20px' }} alt={data?.app} className={data?.app + 'Logo'} />
      </MediaItem>
    )
  }

  const KCC = require('../../assets/images/kcc-logo.svg').default
  const KuCoin = require('../../assets/images/kucoin-logo.svg').default

  const Logo = styled(Image)`
    height: 27px;
    width: auto;
    cursor: pointer;
  `

  const Line = styled.div`
    height: 17px;
    width: 1px;
    background: #5b5b5b;
    margin: 0 18px;
  `

  return (
    <FooterWrap transparent={props?.transparent}>
      <Container>
        <RowBetween style={{ alignItems: 'center', flexFlow: isMobile ? 'column nowrap' : 'row nowrap' }}>
          <Text color={'#FFFFFFCC'}>© {new Date().getFullYear()} KCS All rights reserved</Text>
          <Row style={{ width: 'auto', alignItems: 'center', marginTop: isMobile ? '25px' : '0' }}>
            <a href="https://kcc.io" target="_blank">
              <Logo src={KCC} />
            </a>
            <Line />
            <a href="https://www.kucoin.com" target="_blank">
              <Logo src={KuCoin} />
            </a>
          </Row>
        </RowBetween>
      </Container>
    </FooterWrap>
  )
}

export default Footer
