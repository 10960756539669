import { KCC } from './index'

export interface NavItemType {
  name: string
  hasChildren?: boolean
  hasGroup?: boolean
  route?: string
  childrens?: NavItemChildrenType[] | NavItemGroupType[]
}

export interface NavItemChildrenType {
  title: string
  subTitle: string
  route: string
  icon: string
  setOpenKeys?: any
}

export interface NavItemGroupType {
  groupName: string
  groupMember: NavItemChildrenType[]
}

export const MENU_LIST = [
  {
    name: 'Home',
    route: '/',
    hasChildren: false,
  },
  {
    name: 'KCS Ecosystem',
    route: '/kcs-ecosystem',
    hasChildren: false,
  },
  {
    name: 'GoDAO',
    route: 'https://godao.community',
    hasChildren: false,
  },
  {
    name: 'Token Distribution',
    route: 'token-distribution',
    hasChildren: false,
  },

  {
    name: 'KCS Status',
    route: '/kcs',
    hasChildren: false,
  },
]
