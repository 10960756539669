import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Image } from '../../components'

const P404 = require('../../assets/images/404/404.png').default
const LeftBg = require('../../assets/images/404/left-bg.png').default
const RightBg = require('../../assets/images/404/right-bg.png').default

const Wrap = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 80px);
  background: #0e1114;
  position:relative;
  z-index:3 ;
  @media (max-width: 768px) {
    height: calc(100vh - 140px);
    padding:0 24px;
  }
`
const ContentWrap = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 1300px;
  height: 700px;
  margin: 0 auto;
  background: url(${LeftBg}) 0px 500px no-repeat, url(${RightBg}) right 0px no-repeat;
  @media (max-width: 768px) {
    height: 500px;
    background: url(${LeftBg}) 0px 300px no-repeat, url(${RightBg}) right 0px no-repeat;
  }
`
const MainPic = styled.div`
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 480px;
  height: auto;
  @media (max-width: 768px) {
    width: 80%;
    height: auto;
  }
`

const Button = styled.div`
  width: 140px;
  height: 48px;
  border: 1px solid #00ce87;
  box-sizing: border-box;
  font-family: 'Barlow';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  /* identical to box height, or 200% */
  display: flex;
  align-items: center;
  justify-content: center;
  color: #00ce87;
  margin-top: 80px;
  cursor: pointer;
  position:relative ;
  z-index:2 ;
  transition:all 0.3s ease-in-out ;
  &:hover {
    background: #00ce87;
    color:#fff;
  }
`

const NotFound: React.FunctionComponent = () => {

  const history = useHistory()

  return (
    <Wrap>
      <ContentWrap>
        <MainPic>
          <Image src={P404} width="100%" height="auto" />
        </MainPic>
        <Button onClick={()=>{history.push('/')}}> Return Home </Button>
      </ContentWrap>
    </Wrap>
  )
}

export default NotFound
