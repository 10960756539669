import React from 'react'
import styled from 'styled-components'
import { theme } from '../../constants/theme'
import { Advantage } from './components/Advantage'
import { Card } from './components/Card'
import { Slider } from './components/Slider'
import { Synergy } from './components/Synergy'
import { FadeInUp } from '../../utils/animation'
import { LoadingOutlined } from '@ant-design/icons'
import { Box } from '../../components/Box'
import ReactPlayer from 'react-player'
import Helmet from 'react-helmet'
import { useResponsive } from '../../utils/responsive'
import { Focus } from './components/Focus'

interface Props {}

const HomePageWrap = styled.div`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background: #0c1013;
`

const BannerImage = styled.video`
  width: 100%;
  height: auto;
  position: relative;
  top: 0;
  left: 0;
  z-index: 0;
`

const mBannerBg = require('../../assets/images/home/m-banner-bg.png').default

const BannerWrap = styled.div`
  height: auto;
  position: relative;
  min-height: 600px;
  z-index: 3;
  @media (max-width: 768px) {
    background: url(${mBannerBg}) top center no-repeat;
    background-size: 100% 100%;
  }
`

const BannerContent = styled.div`
  width: 100%;
  max-width: 1200px;
  position: absolute;
  z-index: 999;
  top: 26%;
  left: calc((100vw - 1200px) / 2);
  @media (max-width: 1200px) {
    left: 10px;
  }
  @media (max-width: 768px) {
    top: 84px;
    position: relative;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    left: 0px;
    padding-top: 80px;
  }
`

const KCSTitle = styled.h1`
  font-family: 'Barlow Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 90px;
  line-height: 60px;
  /* identical to box height, or 111% */
  color: ${theme.colors.primary};
  @media (max-width: 768px) {
    text-align: center;
  }
`
const SubTitle = styled.div`
  font-family: Barlow;
  font-style: normal;
  font-weight: 400;
  font-size: 36px;
  line-height: 60px;
  /* identical to box height, or 167% */
  color: #ffffff;
  @media (max-width: 768px) {
    font-size: 24px;
    text-align: center;
  }
`

const StartButton = styled.div`
  width: 200px;
  height: 64px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-family: Barlow;
  font-style: normal;
  font-weight: normal;
  color: #fff;
  margin-top: 32px;
  cursor: pointer;
  transition: all ease-in-out 0.2s;
  &:hover {
    font-size: 25px;
  }
  @media (max-width: 768px) {
    margin: 32px auto;
  }
`

const scrollSmoothTo = function (position: any) {
  if (!window.requestAnimationFrame) {
    ;(window as any).requestAnimationFrame = function (callback: any, element: any): any {
      return setTimeout(callback, 17)
    }
  }

  let scrollTop = document.documentElement.scrollTop || document.body.scrollTop

  const step = function () {
    const distance = position - scrollTop
    scrollTop = scrollTop + distance / 5
    if (Math.abs(distance) < 1) {
      window.scrollTo(0, position)
    } else {
      window.scrollTo(0, scrollTop)
      requestAnimationFrame(step)
    }
  }
  step()
}

export const HomePage = () => {
  const { isMobile } = useResponsive()

  const ref = React.useRef<any>(null)

  const sliderRef = React.useRef<any>(null)

  const [videoLoaded, setVideoLoaded] = React.useState<boolean>(false)

  const loadedHandle = () => {
    console.log('console.log')
    setVideoLoaded(() => true)
  }

  const videoUrl = '/banner.mp4'

  const start = () => {
    console.log(sliderRef.current.offsetTop)
    scrollSmoothTo(sliderRef.current.offsetTop)
  }

  return (
    <HomePageWrap>
      <Helmet>
        <title>KCS Token | KCS Value | KCS Foundation</title>
        <link rel="canonical" href="https://www.kcs.foundation" />
        <meta
          name="description"
          content="KCS is a cryptocurrency token that powers the KuCoin Community Chain (KCC) ecosystem. Learn more about the wide range of KCS applications, use cases, values and benefits."
        />
        <meta name="keywords" content="KCS, KCS token, KCS coin, KCS value, KuCoin KCS" />
        <meta property="og:url" content="https://www.kcs.foundation" />
        <meta property="og:title" content="KCS Token | KCS Value | KCS Foundation" />
        <meta
          property="og:description"
          content="KCS is a cryptocurrency token that powers the KuCoin Community Chain (KCC) ecosystem. Learn more about the wide range of KCS applications, use cases, values and benefits."
        />

        <meta name="twitter:title" content="KCS Token | KCS Value | KCS Foundation" />
        <meta
          name="twitter:description"
          content="KCS is a cryptocurrency token that powers the KuCoin Community Chain (KCC) ecosystem. Learn more about the wide range of KCS applications, use cases, values and benefits."
        />
        <meta name="twitter:card" content="summary" />
        <meta property="twitter:image" content="/kcs64.png" />
      </Helmet>
      {/* Banner */}
      <BannerWrap>
        {!videoLoaded && !isMobile && (
          <Box
            style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '800px' }}
          >
            <LoadingOutlined style={{ color: 'rgba(255,255,255,0.2)', fontSize: '60px', fontWeight: 100 }} />
          </Box>
        )}

        {!isMobile && (
          <ReactPlayer
            width="100%"
            height={!videoLoaded ? '110px' : 'auto'}
            muted
            ref={ref}
            controls={false}
            autoPlay={true}
            loop={true}
            url={videoUrl}
            playing={true}
            onReady={loadedHandle}
            config={{
              file: {
                forceVideo: true,
              },
            }}
          />
        )}
        <BannerContent>
          <FadeInUp delay={600}>
            <KCSTitle>KCS</KCSTitle>
            <SubTitle style={{ marginTop: '15px' }}>A blockchain-based value</SubTitle>
            <SubTitle>self-circulation ecosystem</SubTitle>
            <StartButton onClick={() => start()}>Start Now</StartButton>
          </FadeInUp>
        </BannerContent>
      </BannerWrap>
      <Slider sliderRef={sliderRef} />
      <Synergy />
      <Advantage />
      <Focus />
      <Card />
    </HomePageWrap>
  )
}

export default HomePage
