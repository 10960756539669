export const theme = {
  colors: {
    // primary: '#31D7A0',
    primary: '#00ce87',
    second: '#76E2B3',
    logo: '#39E2A5',
    bridgePrimay: '#31D7A0',
    text: '#fff',
  },
}
