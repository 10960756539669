import { relative } from 'path'
import React from 'react'
import { FunctionComponent } from 'react'
import styled from 'styled-components'
import AppFooter from '../components/AppFooter'
import AppHeader from '../components/AppHeader/index'
import Footer from '../components/Footer'
import { isMobile } from 'react-device-detect'
import { useResponsive } from '../utils/responsive'

const AppContentWrap = styled.div`
  height: auto;
  min-height: calc(100vh - 400px);
`

const StartBg = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 0;
`

// Not fullscreen mode
const AppBaseLayout: FunctionComponent = ({ children }) => {
  const { isMobile } = useResponsive()

  const settings = (width:number, height:number) => {
    return {
      width: width,
      height: height,
      autoResize: true /*enable/disable autoResize*/,
      autoResizeMinWidth: null /*set autoResize min width*/,
      autoResizeMaxWidth: null /*set autoResize max width*/,
      autoResizeMinHeight: null /*set autoResize min height*/,
      autoResizeMaxHeight: null /*set autoResize max height*/,
      addMouseControls: false /*enable/disable mouse controls*/,
      addTouchControls: true /*enable/disable touch controls*/,
      hideContextMenu: true /*enable/disable canvas context menu*/,
      starCount: 666 /*count of active/moving stars*/,
      starBgCount: 0 /*count of inactive/background stars*/,
      starBgColor: { r: 255, g: 255, b: 255 } /*background stars color*/,
      starBgColorRangeMin: 10 /*background stars color range min of starBgColor*/,
      starBgColorRangeMax: 60 /*background stars color range max of starBgColor*/,
      starColor: { r: 255, g: 255, b: 255 } /*stars color*/,
      starColorRangeMin: 10 /*stars color range min of starBgColor*/,
      starColorRangeMax: 100 /*stars color range max of starBgColor*/,
      starfieldBackgroundColor: { r: 11, g: 16, b: 19 } /*background color*/,
      starDirection: 1 /*stars moving in which direction*/,
      starSpeed: 20 /*stars moving speed*/,
      starSpeedMax: 200 /*stars moving speed max*/,
      starSpeedAnimationDuration: 6 /*time in seconds from starSpeed to starSpeedMax*/,
      starFov: 300 /*field of view*/,
      starFovMin: 200 /*field of view min*/,
      starFovAnimationDuration: 2 /*time in seconds from starFov to starFovMin*/,
      starRotationPermission: true /*enable/disable rotation*/,
      starRotationDirection: 1 /*rotation direction*/,
      starRotationSpeed: 0.0 /*rotation speed*/,
      starRotationSpeedMax: 2.0 /*rotation speed max*/,
      starRotationAnimationDuration: 2 /*time in seconds from starRotationSpeed to starRotationSpeedMax*/,
      starWarpLineLength: 7.0 /*line length*/,
      starWarpTunnelDiameter: 100 /*tunnel diameter*/,
      starFollowMouseSensitivity: 0.025 /*mouse follow sensitivity*/,
      starFollowMouseXAxis: true /*enable/disable mouse follow x axis*/,
      starFollowMouseYAxis: true /*enable/disable mouse follow y axis*/,
    }
  }

  React.useEffect(() => {
    // @ts-ignore
    if (WarpDrive && !isMobile && process.env.NODE_ENV==='production') {
      const width = document.body.clientWidth
      const height = document.body.clientHeight
      //  @ts-ignore
      const warpdrive = new WarpDrive(document.getElementById('start-bg'), settings(width,height))
    }
  }, [settings])

  return (
    <div style={{ position: 'relative',height:'100vh' }}>
      <StartBg>
        <div id="start-bg"></div>
      </StartBg>
      <AppHeader />
      <AppContentWrap>{children}</AppContentWrap>
      <Footer />
    </div>
  )
}

// App layout
const AppLayout: FunctionComponent = ({ children }) => {
  const isFullScreen = localStorage.getItem('FULLSCREEN_MODE')

  if (isFullScreen) return <div>{children}</div>
  return <AppBaseLayout>{children}</AppBaseLayout>
}

export default AppLayout
