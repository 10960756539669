import React, { FunctionComponent } from 'react'
import { ConfigProvider } from 'antd'
import { Provider } from 'react-redux'
import store from './state'
import { HashRouter, BrowserRouter } from 'react-router-dom'


const Providers: FunctionComponent = ({ children }) => {
  return (
    <ConfigProvider>
      <Provider store={store}>
        <BrowserRouter>{children}</BrowserRouter>
      </Provider>
    </ConfigProvider>
  )
}

export default Providers
