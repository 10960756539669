import React from 'react'
import styled from 'styled-components'
import SloganPicture from '../../../components/SloganPicture'
import { useResponsive } from '../../../utils/responsive'
import { FadeInUp } from '../../../utils/animation'
import { marginTop } from 'styled-system'

interface Props {}

const AdvantageWrap = styled.div`
  width: 100%;
  padding: 0 20px;
  z-index: 1;
  position: relative;
`

const AdvantageContent = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 120px;
  padding-bottom: 120px;
  @media (max-width: 768px) {
    padding-top: 40px;
    padding-bottom: 0px;
  }
`

const Characteristics = [
  {
    title: 'Deflationary Mechanism',
    description: (
      <div>
        KCS Deflationary Mechanism: On the one hand, the KuCoin trading platform will regularly repurchase KCS from the
        secondary market with part of the platform’s revenue and burn this part of KCS. This means that there will be
        less and less KCS supply in the market. In the past 4 years, KuCoin has burned tens of millions of KCS, worth
        hundreds of millions of dollars. <br />
        <div style={{ marginTop: '40px' }}>
          On the other hand, in order to better support the development of the KCS ecosystem, we plan to introduce
          EIP1559, the KCC fee mechanism of base fee + priority fee. Among them, the basic fuel fee will be dynamically
          adjusted according to the congestion of the KCC network and will lead to the continuous destruction and
          deflation of KCS. The priority gas fee will go to the corresponding miner node. In this way, both developers
          and KCS holders will benefit from this.
        </div>
      </div>
    ),
    image: require('../../../assets/images/home/4.svg').default,
  },
  {
    title: 'Reward Mechanism',
    description:
      "KCS Incentive Model: The KuCoin platform will repurchase KCS with part of its fees and distribute it to users according to the user's KCS position on a daily basis. In the past 4 years, the KuCoin trading platform has cumulatively distributed tens of millions of KCS incentives, which is worth hundreds of millions of dollars.",
    image: require('../../../assets/images/home/1.svg').default,
  },
  {
    title: 'Fee Discounts',
    description:
      'KuCoin uses the size of a user’s KCS holdings as the criterion for determining users’ VIP level. The more KCS held, the higher the VIP level, the more benefits and the greater the fee discounts. In addition, users can also use KCS to pay for transaction fees on the KuCoin platform and enjoy discounts.',
    image: require('../../../assets/images/home/2.svg').default,
  },
  // {
  //   title: 'KCS Burning',
  //   description:
  //     'KuCoin regularly performs KCS buyback and burns from the secondary market with a portion of platform revenues to gradually reduce the supply of KCS on the market. Over the past four years, KuCoin has burned tens of millions of KCS, worth hundreds of millions of dollars. ',
  //   image: require('../../../assets/images/home/3.svg').default,
  // },
]

export const Advantage = (props: Props) => {
  const { isMobile } = useResponsive()
  const CharacteristicsComponent = Characteristics.map((item, index) => {
    let dir = ''
    if (isMobile) {
      dir = index % 2 === 1 ? 'left' : 'left'
    } else {
      dir = index % 2 === 0 ? 'left' : 'right'
    }
    return (
      <SloganPicture
        key={index}
        width={isMobile ? '108px' : '334px'}
        url={item.image}
        description={item.description}
        title={item.title}
        direction={dir}
      />
    )
  })

  return (
    <AdvantageWrap>
      <AdvantageContent>{CharacteristicsComponent}</AdvantageContent>
    </AdvantageWrap>
  )
}
