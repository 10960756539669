export const KCC = {
  FULLNAME: 'KuCoin Community Chain',
  ABBR: 'KCC',
  EXPLORER: 'https://explorer.kcc.io',
  TEST_EXPLORER: 'https://scan-testnet.kcc.network',
  FAUCET: 'https://faucet-testnet.kcc.network/',
  DOCS_URL: 'https://docs.kcc.io/#/',
  GITHUB_URL: 'https://github.com/kcc-community',
  DAPP_URL: 'https://github.com/kcc-community/awesome-dApps',
  DISCORD_URL: 'https://discord.com/invite/H5ucJydSyd',
  MEDIA_URL: 'https://kccofficial.medium.com',
  MEDIA_API: 'https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@kccofficial',
  MAIL_SUBSCRIBE_PROXY: 'https://mailsubscribe.kcc.io/mailchimp-subscribe',
  TWITTER: 'https://twitter.com/KCCOfficialTW',
  TELEGRAM: 'https://t.me/KCCOfficialEnglishCommunity',
  RISK_STATMENT: 'id=risk-statement',
  DISCLAIMER: 'id=disclaimers',
  FAQ: 'id=faq',
  GRANTS: 'https://github.com/kcc-community/kcc-grants',
  DISCOVER: 'https://discover.kcc.io',
  DAO: 'https://godao.community',
  SAFE_GNOSIS: 'https://safe.kcc.io',
  CONTACT_LIST: [
    {
      app: 'Twitter',
      icon: require('../assets/images/Icons/app/twitter-logo@2x.png').default,
      route: 'https://twitter.com/KCCOfficialTW',
    },
    {
      app: 'Telegram',
      icon: require('../assets/images/Icons/app/telegram-logo@2x.png').default,
      route: 'https://t.me/KCCOfficialEnglishCommunity',
    },
    {
      app: 'Github',
      icon: require('../assets/images/Icons/app/github-logo@2x.png').default,
      route: 'https://github.com/kcc-community',
    },
    {
      app: 'Discord',
      icon: require('../assets/images/Icons/app/discord-logo@2x.png').default,
      route: 'https://discord.com/invite/H5ucJydSyd',
    },
  ],
}

export const media = [
  {
    app: 'Twitter',
    icon: require('../assets/images/Icons/twitter-logo@2x.png').default,
    hover: require('../assets/images/Icons/twitter-logo-hover@2x.png').default,
    route: 'https://twitter.com/KCCOfficialTW',
  },
  {
    app: 'Telegram',
    icon: require('../assets/images/Icons/telegram-logo@2x.png').default,
    hover: require('../assets/images/Icons/telegram-logo-hover@2x.png').default,
    route: 'https://t.me/KCCOfficialEnglishCommunity',
  },
  {
    app: 'Medium',
    icon: require('../assets/images/Icons/medium-logo@2x.png').default,
    hover: require('../assets/images/Icons/medium-logo-hover@2x.png').default,
    route: 'https://kccofficial.medium.com/',
  },
  {
    app: 'Discord',
    icon: require('../assets/images/Icons/discord-logo@2x.png').default,
    hover: require('../assets/images/Icons/discord-logo-hover@2x.png').default,
    route: 'https://discord.com/invite/H5ucJydSyd',
  },
]

export const APP = {
  TOKEN_INFO_CDN: 'https://cdn.jsdelivr.net/gh/kucoin-community-chain/tokens-info@main/icons/',
}

export const KCSLogo = require('../assets/images/kcs-logo.svg').default
export const KCCLogoWithText = require('../assets/images/kcc-logo.svg').default
export const KucoinLogoWithText = require('../assets/images/kucoin-logo.svg').default

export const BuyKCSWithMojitoSwap = 'https://app.mojitoswap.finance/swap'
export const BuyKCSWithKuCoin = 'https://trade.kucoin.com/trade/KCS-USDT'
