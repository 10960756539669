import React from 'react'
import styled from 'styled-components'
import Benefits from './Benefits'
import { FadeInUp } from '../../../utils/animation'

interface Props {}

const SynergyBg = require('../../../assets/images/home/rainbow.png').default

const SynergyWrap = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;
  background: url(${SynergyBg}) top center no-repeat;
  @media (max-width: 768px) {
    height: auto;
    /* background-size: 250% 100%; */
  }
`

const SynergyContent = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`

const Title = styled.h2`
  font-family: 'Barlow Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 80px;
  /* identical to box height, or 246% */
  text-align: center;
  color: #ffffff;
  padding-top: 170px;
  @media (max-width: 768px) {
    font-size: 40px;
    line-height: 80px;
    padding: 0 20px;
    padding-top: 150px;
    line-height: 1.5;
  }
`

const Description = styled.div`
  font-family: Barlow;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 32px;
  /* or 200% */
  text-align: center;
  color: #ffffff;
  max-width: 1116px;
  margin: 40px auto;
  @media (max-width: 768px) {
    padding: 0 20px;
    margin-top: 40px;
  }
`

export const Synergy = (props: Props) => {
  return (
    <SynergyWrap>
      <SynergyContent>
        <FadeInUp>
          <Title>KCS Value Capture and Deflationary Mechanism </Title>
          <Description>
            The KCC ecosystem will aggregate various types of projects and platforms, including DeFi, NFT+, games, Web
            3.0-related application projects, etc. KCS will support and integrate with the entire ecosystem and continue
            to capture value from the following aspects, including the right to participate in the services of related
            products and the repurchase of KCS for the benefit of projects in the ecosystem.
          </Description>
          <Benefits />
        </FadeInUp>
      </SynergyContent>
    </SynergyWrap>
  )
}
